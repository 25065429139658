var requestPagi = function (rel, postperpage, category, page, order, orderby, container) {
  jQuery(container).addClass('loading');
  jQuery.post(ajaxurl,
    {
      action: 'pagi',
      postperpage: postperpage,
      category: category,
      page: page,
      order: order,
      orderby: orderby
    }, function (data) {
      var html = '';
      var conta = 0;
      jQuery(data.posts).each(function () {
        conta++;
        html += '' +
          '<div class="inbook">\n' +
          '    <div class="books">\n' +
          '        <a class="acquistalo" href="' + this.url + '">\n' +
          '            <div class="book">\n' +
          '                <div class="linkaggio">\n' +
          '                    <div class="centering">\n' +
          '                        <div class="clr"></div>\n' +
          '                        SCOPRI\n' +
          '                    </div>\n' +
          '                </div>\n' +
          '                ' + this.imgtag + '\n' +
          '            </div>\n' +
          '        </a>\n' +
          '    </div>\n' +
          '    <div class="titlepricont">\n' +
          '        ' + this.price + '\n' +
          '        <h4>' + this.title + '</h4>\n' +
          '    </div>\n' +
          '</div><div class="pm-linesep pm-linesep-' + conta + '"></div>';

      });
      html += '<div class="loaderlayer"></div>';
      var pagi = '';


      if (data.pagination.before !== '') {
        pagi += '<li class="firstpage" data-rel="' + rel + '" data-page="1" onclick="pagina(this);"> &laquo;</li>' +
          '<li>' + data.pagination.before + '</li>';
      }
      var activepage = '';
      for (var indi = data.pagination.init; indi <= data.pagination.end; indi++) {
        activepage = '';
        if (indi === page) {
          activepage = 'activepage';
        }

        pagi += '<li onclick="pagina(this);"  class="numerico ' + activepage + '" data-rel="' + rel + '" data-page="' + indi + '">' + indi + '</li>';
      }
      if (data.pagination.after !== '') {
        pagi += '<li>' + data.pagination.after + '</li>' +
          '<li onclick="pagina(this);" class="lastpage" data-rel="' + rel + '" data-page="' + data.pagination.maxpage + '"> &raquo;</li>';

      }

      jQuery(container).html(html);
      jQuery('.paginazione ul').html(pagi);
      jQuery(container).removeClass('loading');

    }, 'json');
};

var socialFromat = function (obj) {

  var date = obj.date.split(' ');
  date = date[0];

  var html = '<div class="media socialmedia">' +
    '<a class="pull-left" href="' + obj.link + '" target="_BLANK">' +
    '<img class="media-object" src="/wp-content/themes/medicina/dist/images/' + obj.social + '.png" />' +
    '</a>' +
    '<div class="media-body">' +
    '<div class="datapost media-heading"><strong>' + date + '</strong></div>' +
    '<p>' + obj.message + '</p>' +
    '</div>';

  return html;
};

jQuery(document).ready(function ($) {
  jQuery('#subscribedown .button').on('click', function (e) {

    var check = true;
    var lists = jQuery(this).data('list');
    var email = jQuery('#downemail').val();

    if (!email) {
      check = false;
      jQuery('#downemail').addClass('errato');
    }

    if (check) {
      ga('send', 'event', {'eventCategory': 'button', 'eventAction': 'click', 'eventLabel': 'INVIAEMAIL'});
      jQuery('#subscribedown').addClass('loaderando');
      jQuery.post('/wp-admin/admin-ajax.php',
        {
          action: 'newsletters_api',
          api_method: 'subscriber_add',
          api_key: 'C07F0E23644FE58F7196FDC6552967C8',
          api_data: {
            email: email,
            list_id: lists
          }

        }, function (data) {
          jQuery('#subscribedown').removeClass('loaderando');
          if (data.success) {
            jQuery('#downmailcontainer').fadeOut();
            jQuery('#downfilecontainer').fadeIn();
          } else {
            alert('Abbiamo riscontrato un problema durante l\'iscrizione, riprova!');
          }
        }, 'json');
    }
  });

  jQuery('#registratinl').on('click', function (e) {
    e.preventDefault();
    var formid, lists, name, email = '';
    var check = true;
    formid = jQuery(this).data('formid');


    if (formid) {
      lists = jQuery(formid + ' #areaint').val();
      name = jQuery(formid + ' input[name="name"]').val();
      email = jQuery(formid + ' input[name="email"]').val();
      if (!name) {
        jQuery(formid + ' input[name="name"]').addClass('errato');
        check = false;
      }
      if (!email) {
        jQuery(formid + ' input[name="email"]').addClass('errato');
        check = false;
      }
      if (!lists) {
        check = false;
      }
      if (check) {
        jQuery(formid).addClass('loaderando');
        ga('send', 'event', {'eventCategory': 'button', 'eventAction': 'click', 'eventLabel': 'NEWSLETTER'});
        jQuery.post('/wp-admin/admin-ajax.php',
          {
            action: 'newsletters_api',
            api_method: 'subscriber_add',
            api_key: 'C07F0E23644FE58F7196FDC6552967C8',
            api_data: {
              email: email,
              name: name,
              list_id: lists
            }

          }, function (data) {
            jQuery(formid).removeClass('loaderando');
            if (data.success) {
              jQuery(formid + ' input,' + formid + ' select').val('');
              jQuery(formid).removeClass('loaderando');
              alert('Iscritto correttamente');
            } else {
              alert('Abbiamo riscontrato un problema durante l\'iscrizione, riprova!');
            }
          }, 'json');
      }

    }
  });

  jQuery('.errato').on('focus', function () {
    jQuery(this).removeClass('errato');
  });

  if (jQuery('.socialcont').length) {
    jQuery.post('/wp-admin/admin-ajax.php',
      {action: 'socialLive'},
      function (data) {
        var arr = jQuery.map(data, function (el) {
          return el;
        });
        arr.reverse();
        var max = 4;
        if (arr.length < 4) {
          max = arr.length;
        }

        for (var i = 0; i <= max; i++) {
          jQuery('.socialcont').append(socialFromat(arr[i]));
        }

      }, 'json');
  }

  /**** CONTROLLARE ****/
  jQuery('.aprimodal').on('click', function (e) {
    e.preventDefault();
    jQuery('#myPrivacy').modal('show');
  });

  jQuery('.switch').on('click', function () {
    var id = jQuery(this).data('rel');
    jQuery(this).toggleClass('listing');
    jQuery(id).toggleClass('listing');
  });

  jQuery('.orderby').on('change', function () {
    //jQuery('.paginazione ul li.activepage').trigger('click');
    var rel = jQuery(this).data('rel');
    var postperpage = jQuery(rel).data('postsperpage');
    var category = jQuery(rel).data('category');
    var container = jQuery(rel).data('cont');
    var order = jQuery(this).val();
    var orderby = jQuery(this).find(":selected").data('orderby');
    requestPagi(rel, postperpage, category, 1, order, orderby, container);
  });

  jQuery('.gatrack').click(function (event) {
    ga('send', 'event', {'eventCategory': 'contatti', 'eventAction': 'click', 'eventLabel': 'informazioni-mail'});
  });

  jQuery('a[href^="mailto:"]', jQuery('.gatrackmail')).click(function (event) {
    var em = jQuery(this).attr('href');
    em = em.replace('mailto:', '');
    em = jQuery.trim(em);
    ga('send', 'event', {'eventCategory': 'contatti', 'eventAction': 'click', 'eventLabel': 'mail-ufficioabbonamenti'});
  });


  // tracciamneti FB
  if (jQuery('.infosidelibro').length) {
    if (typeof fbq === "function") {
      fbq('track', 'ViewContent');
    }
  }
  if (jQuery('body').hasClass('page-template-page-thankyou')) {
    if (typeof fbq === "function") {
      fbq('track', 'Purchase');
    }
  }
});

var pagina = function (element) {
  if (jQuery(element).hasClass('activepage')) {
    return false;
  }
  var rel = jQuery(element).data('rel');
  var postperpage = jQuery(rel).data('postsperpage');
  var category = jQuery(rel).data('category');
  var container = jQuery(rel).data('cont');
  var page = jQuery(element).data('page');
  var order = jQuery(rel + ' .orderby').val();
  var orderby = jQuery(rel + ' .orderby :selected').data('orderby');
  requestPagi(rel, postperpage, category, page, order, orderby, container);
};

jQuery('.page-id-23257 .paginazione').on('click', 'li', function (e) {

  e.preventDefault();

  var pagina = jQuery(this).text();
  pagina = pagina - 1;

  jQuery.post(ajaxurl, {
    action: 'caricanovita',
    pagina: pagina,
    dimensione: 10,
  }, function (res) {

    var response = jQuery.parseJSON(res);

    var tgtnov = jQuery('#novita');
    tgtnov.empty();
    tgtnov.append(response.posts);

    var tgtpag = jQuery('.paginazione');
    tgtpag.empty();
    tgtpag.append(response.pagination);
  });

});

jQuery('#mailpoet').on('submit', function () {
  jQuery.post('/wp-admin/admin-ajax.php',
    {action: 'mailpoet', name: jQuery('#name').val(), email: jQuery('#email').val()},
    function () {
      ga('send', 'event', {'eventCategory': 'newsletter-form', 'eventAction': 'click', 'eventLabel': 'iscrizione-newsletter'});
      jQuery('#mailpoet').hide();
      jQuery('.thankyou').show();
    });
  return false;
});
