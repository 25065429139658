function initPaciniMedicina() {

    /*  window.cookieconsent.initialise({
          'palette': {
              'popup': {
                  'background': '#cc071e',
              },
              'button': {
                  'background': '#ffffff',
              },
          },
          'position': 'bottom-right',
          'content': {
              'dismiss': 'Accetta e continua',
              'message': 'Questo sito utilizza cookies tecnici funzionali all\'esperienza di navigazione e cookies, anche di terze parti, per offrirti servizi in linea con le tue preferenze.\r\nSe vuoi saperne di pi\u00F9 o negare il consenso a tutti o ad alcuni dei suddetti cookies clicca il pulsante \"informativa estesa\". Se accetti cliccando su \"Accetta e continua\" acconsenti all\'uso dei cookie.',
              'link': 'Informativa Estesa',
              'href': 'https://www.pacinimedicina.it/cookie',
          },
      });*/



    jQuery('#carousel-example-generic .carousel-inner .item:first-of-type').addClass('active');
    jQuery('#carousel-example-generic .carousel-indicators li:first-of-type').addClass('active');

    jQuery('.owl-carousel').each(function (i, e) {
        var tgt = jQuery(e);
        var owl_max = 5;
        if (tgt.data('max')) {
            owl_max = tgt.data('max');
        }
        var owl_loop = false;
        if (tgt.data('loop')) {
            owl_loop = tgt.data('loop');
        }
        var owl_margin = 0;
        if (tgt.data('margin')) {
            owl_margin = tgt.data('margin');
        }

        var owl_obj_respo = {};
        if (owl_max === 1) {
            owl_obj_respo = { 0: { items: 1 } };
        } else if (owl_max === 2) {
            owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 } };
        } else if (owl_max === 3) {
            //owl_obj_respo = {0: {items: 1}, 480: {items: 2}, 768: {items: 3}};
            owl_obj_respo = { 0: { items: 1 }, 768: { items: 2 }, 900: { items: 3 } };

        } else if (owl_max === 4) {
            owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 4 } };
        } else if (owl_max === 5) {
            owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 3 }, 1000: { items: 5 } };
        } else if (owl_max === 6) {
            owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 3 }, 900: { items: 5 }, 1100: { items: 6 } };
        }
        tgt.on('initialized.owl.carousel', function (event) {
            console.log('initialized.owl.carousel');
            tgt.addClass('owl-carousel-show');
        });
        tgt.owlCarousel({
            items: owl_max,
            nav: true,
            dots: false,
            lazyLoad: true,
            responsive: owl_obj_respo,
            margin: owl_margin,
            loop: owl_loop,
        });
    });

    if (jQuery('#videopassword').length) {
        jQuery('#videopassword').on('submit', function (event) {
            event.preventDefault();
            jQuery('#password').removeClass('error');
            if (window.btoa(jQuery('#password').val()) === jQuery(this).attr('data-pwd')) {
                jQuery('.password').fadeOut();
                jQuery('.listavideo').fadeIn();
            } else {
                jQuery('#password').addClass('error');
            }
            return false;
        });
    }

    jQuery("#billing_invoice_type option[value='professionist_invoice']").remove();
    jQuery('#billing_invoice_type').on('select2:select', function (e) {
        if (jQuery(this).val() === 'private_invoice') {
            jQuery('.pm-richiedi-ricevuta-field').show(200);
        } else {
            jQuery('.pm-richiedi-ricevuta-field').hide(200);
        }
        //console.log('eccolo', jQuery(this).val());
    });
    if (jQuery('#billing_invoice_type').val() === 'private_invoice') {
        jQuery('.pm-richiedi-ricevuta-field').show(200);
    } else {
        jQuery('.pm-richiedi-ricevuta-field').hide(200);
    }

    jQuery('.woovr-variation.woovr-variation-radio').click(function () {
        jQuery('.woocommerce-variation-add-to-cart.variations_button').addClass('clicked');
    });

    //jQuery('.infosidelibro > p.price > ins').append('<span class="pe19-promo-info-single">PROMOZIONE FINO AL 31 DICEMBRE 2020</span>');
    //jQuery('.woovr-variation-price ins').append('<span class="pe19-promo-info-var">PROMOZIONE FINO AL 31 DICEMBRE 2020</span>');

}

function checkInitMedicina() {
    jQuery(document).ready(function () {
        jQuery('label[for=payment_method_ppcp-gateway]').after( "<a href='https://www.paypal.com/it/webapps/mpp/home' class='link-paypal-custom' target='_blank'>Che cos'è Paypal?</a>" );
        jQuery('body').imagesLoaded({ background: true }, function () {
            // console.log("loaded");
            initPaciniMedicina();
        });
    });

    document.addEventListener('wpcf7submit', function (event) {
        // console.log(event);
        /*
        _iub.cons_instructions.push(["submit", {
            submitElement: document.getElementById("cf7_submit"),
            writeOnLocalStorage: false, // default: false
            form: {
                selector: document.getElementById("cf7_form"),
                map: {
                    subject: {
                        first_name: "nome",
                        email: "email"
                    },
                    preferences: {
                        privacy: "privacy",
                    }
                }
            },
            consent: {
                legal_notices: [
                    {
                        identifier: 'privacy_policy',
                    }, {
                        identifier: 'cookie_policy',
                    }, {
                        identifier: 'term',
                    }
                ],
            }
        }, {
                success: function (response) {
                    // console.log(response);
                },
                error: function (response) {
                    // console.log(response);
                }
            }
        ]);
        */
        if (event.detail.status === "mail_sent") {
            // alert( "Fire!" );

            var frm = jQuery('form', this);

            var id_lista = 4;
            var id_gruppo = jQuery('.wrap-form-preorder-product').attr('data-list-ml');
            var _mail = '';
            var _nome = '';

            console.log("ID GRUPPO --> ", id_gruppo);

            if (jQuery("input[name='email']", frm).length) {
                _mail = jQuery("input[name='email']", frm).val();
            }

            if (jQuery("input[name='nome']", frm).length) {
                _nome = jQuery("input[name='nome']", frm).val();
            }

            var formData = {
                action: 'formPreorderProduct',
                email: _mail,
                nome: _nome,
                id_group: id_gruppo,
                //id_list: 1,
                id_list: id_lista,
                //data_title: $(document).find("title").text(),
                data_link: location.href,
                //fld_names: 'email',
                //fld_values: _mail,
            };

            if (jQuery("input[name='provincia']", frm).length) {
                formData.provincia = jQuery("input[name='provincia']", frm).val();
            }

            if (jQuery("input[name='professione']", frm).length) {
                formData.professione = jQuery("input[name='professione']", frm).val();
            }

            console.log(formData);

            jQuery.ajax({
                url: ajaxurl,
                method: 'post',
                data: formData,
                dataType: 'json',
                // success: function(data) {},
                // complete: function(){},
                // beforeSend: function(){}
            });


        }

    }, false);

}
